import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  show(evt) {
    const { name } = evt.params;
    if (name) {
      const dialog = document.querySelector(`[data-dialog="${name}"]`);
      if (dialog) {
        dialog.show();
      } else {
        console.warn(`Dialog with name "${name}" not found`);
      }
    }
  }

  toggle(evt) {}
}
