import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

const TOUR_STEPS = [
  {
    dialogTarget: "welcomeDialog",
  },
  {
    popoverTarget: "dealNetwork",
    highlightTarget: "dealNetworkHighlight",
  },
  {
    popoverTarget: "projects",
    highlightTarget: "projectsHighlight",
  },
  {
    popoverTarget: "buyerSearch",
    highlightTarget: "buyerSearchHighlight",
  },
  {
    popoverTarget: "chooseBuyerType",
    highlightTarget: "chooseBuyerTypeHighlight",
  },
].map((step, idx) => {
  step.num = idx + 1;
  return step;
});

const _targets = TOUR_STEPS.reduce((acc, step) => {
  if (step.dialogTarget) {
    acc.push(step.dialogTarget);
  }
  if (step.popoverTarget) {
    acc.push(step.popoverTarget);
  }
  if (step.highlightTarget) {
    acc.push(step.highlightTarget);
  }
  return acc;
}, []);

export default class extends Controller {
  static targets = ["state"].concat(_targets);

  connect() {
    this.handleResize = debounce(this.handleResize.bind(this), 250);
    window.addEventListener("resize", this.handleResize);
    this.tourActive = false;
    this.tourSuspended = false;

    if (this.hasWelcomeDialogTarget) {
      this.welcomeDialogTarget.addEventListener("sl-request-close", (event) => {
        if (["keyboard", "overlay"].includes(event.detail.source)) {
          event.preventDefault();
        }
      });
    }
    this.__initialized = true;
    this.setState();
  }

  get currentStep() {
    return TOUR_STEPS.find((step) => step.num === this.state_d.currentStep);
  }

  setState() {
    if (this.hasStateTarget) {
      this.state_d = JSON.parse(this.stateTarget.innerHTML);
      if (this.state_d.status === "completed") {
        this.endTour();
      } else {
        this.startTour();
      }
    } else {
      console.warn("No state target found");
    }
  }

  stateTargetConnected(element) {
    if (!this.__initialized) {
      console.warn("State target connected before controller initialized");
      return;
    }
    this.setState();
  }

  disconnect() {
    window.removeEventListener("resize", this.handleResize);
  }

  startTour() {
    if (this.isSmallScreen()) {
      console.log("Tour not available on small screens");
      return;
    }

    if (!this.currentStep) {
      console.warn("Step not found for state", this.state_d);
      return;
    }

    this.tourActive = true;
    this.tourSuspended = false;
    this.currentStepNum = this.currentStep.num;
    this.showCurrentStep();
  }

  showCurrentStep() {
    const step = this.currentStep;
    if (step.dialogTarget) {
      this.hideOtherSteps();
      this.showDialogStep(step);
    } else if (step.popoverTarget) {
      this.hideOtherSteps();
      this.showPopoverStep(step);
    } else {
      console.warn("No target found for step", step);
    }
  }

  hideOtherSteps() {
    TOUR_STEPS.filter((step) => {
      return step.num != this.currentStepNum;
    }).forEach((step) => {
      if (step.dialogTarget) {
        const el = this.targetIfPresent(step.dialogTarget);
        if (el) {
          el.hide();
        } else {
          console.warn("Dialog not found for step", step);
        }
      }
      if (step.popoverTarget) {
        this.cleanupTourElements();
      }
    });
  }

  showDialogStep(step) {
    if (!step.dialogTarget) {
      console.warn("No dialogTarget found for step", step);
      return;
    }
    const dialog = this[`${step.dialogTarget}Target`];
    if (!dialog) {
      console.warn("Dialog not found for step", step);
      return;
    }
    console.log("Show dialog", dialog);
    dialog.show();
  }

  showPopoverStep(step) {
    if (!step.popoverTarget) {
      console.warn("No popoverTarget found for step", step);
      return;
    }
    if (!step.highlightTarget) {
      console.warn("No highlightTarget found for step", step);
      return;
    }
    const popoverContent = this[`${step.popoverTarget}Target`];
    if (!popoverContent) {
      console.warn("Popover not found for step", step);
      return;
    }
    const highlightTarget = this[`${step.highlightTarget}Target`];
    if (!highlightTarget) {
      console.warn("Highlight target not found for step", step);
      return;
    }
    this.updateTourElements();
  }

  handleResize() {
    if (this.isSmallScreen()) {
      if (this.tourActive) {
        this.suspendTour();
      }
    } else {
      if (this.tourSuspended) {
        this.resumeTour();
      } else if (this.tourActive) {
        this.updateTourElements();
      }
    }
  }

  isSmallScreen() {
    return window.innerWidth < 768; // Adjust this breakpoint as needed
  }

  suspendTour() {
    this.cleanupTourElements();
    this.tourSuspended = true;
  }

  resumeTour() {
    this.tourSuspended = false;
    this.tourActive = true;
  }

  updateTourElements() {
    if (!this.backdrop) {
      this.createBackdrop();
    }
    this.updateBackdropClip();
    if (!this.popover) {
      this.createPopover();
    }
    this.updatePopoverContent();
    this.updatePopoverPosition();
    this.updateHighlightPosition();
  }

  createBackdrop() {
    this.backdrop = document.createElement("div");
    this.backdrop.classList.add(
      "fixed",
      "inset-0",
      "bg-black",
      "bg-opacity-50",
      "z-40"
    );
    document.body.appendChild(this.backdrop);
  }

  updateBackdropClip() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    this.backdrop.style.clipPath = `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, ${rect.right}px 0%, ${rect.right}px ${rect.bottom}px, ${rect.left}px ${rect.bottom}px, ${rect.left}px ${rect.top}px, ${rect.right}px ${rect.top}px, ${rect.right}px 0%)`;
  }

  createPopover() {
    this.popover = document.createElement("div");
    this.popover.classList.add("fixed", "z-50", "max-w-sm");
    document.body.appendChild(this.popover);
  }

  updatePopoverContent() {
    if (!this.currentStep.popoverTarget) {
      console.warn("No popoverTarget found for current step", this.currentStep);
      return;
    }
    if (this.currentStep.popoverTarget) {
      const popoverContent = this[`${this.currentStep.popoverTarget}Target`];
      this.popover.innerHTML = popoverContent.innerHTML;
    }
  }

  updatePopoverPosition() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    this.popover.style.top = `${rect.bottom + 10}px`;
    this.popover.style.left = `${rect.left}px`;
  }

  highlightCurrentTarget() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    this.highlightedElement = currentTarget.cloneNode(true);
    this.highlightedElement.classList.add(
      "fixed",
      "z-50",
      "ring-2",
      "ring-blue-500",
      "bg-white"
    );
    document.body.appendChild(this.highlightedElement);
    this.updateHighlightPosition();
  }

  updateHighlightPosition() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for highlight position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    console.log("Highlighting", rect);
    if (this.highlightedElement) {
      this.highlightedElement.style.top = `${rect.top}px`;
      this.highlightedElement.style.left = `${rect.left}px`;
      this.highlightedElement.style.width = `${rect.width}px`;
      this.highlightedElement.style.height = `${rect.height}px`;
    }
    if (this.popover) {
      this.popover.style.top = `${rect.bottom + 10}px`;
      this.popover.style.left = `${rect.left}px`;
    }
  }

  targetIfPresent(targetName) {
    const hasMethod = `has${
      targetName.charAt(0).toUpperCase() + targetName.slice(1)
    }Target`;
    if (this[hasMethod]) {
      return this[`${targetName}Target`];
    } else {
      return null;
    }
  }

  getCurrentHighlightTarget() {
    const step = this.currentStep;
    if (step && step.highlightTarget) {
      return this[`${step.highlightTarget}Target`];
    } else {
      console.warn("No highlightTarget found for current step", step);
    }
  }

  cleanupTourElements() {
    if (this.backdrop) this.backdrop.remove();
    if (this.popover) this.popover.remove();
    if (this.highlightedElement) this.highlightedElement.remove();
    if (this.hasWelcomeDialogTarget) this.welcomeDialogTarget.hide();
    this.backdrop = null;
    this.popover = null;
    this.highlightedElement = null;
  }

  endTour() {
    this.cleanupTourElements();
    this.tourActive = false;
    this.tourSuspended = false;
  }
}
