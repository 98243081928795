import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = [];

  connect() {
    tippy(this.element, {
      content: this.element.getAttribute("data-content"),
      allowHTML: true,
    });
  }
}
