import { Controller } from "@hotwired/stimulus";

const QUERY_METHOD_PARAM = "query_method";

export default class extends Controller {
  static targets = ["queryMethodField"];

  /*
   * Slight hack because sometimes the panel doesn't show on the first render, idk why
   */
  connect() {
    if (this.queryMethodFieldTarget.value) {
      this.element.show(this.queryMethodFieldTarget.value);
    }
  }

  showTab(evt) {
    let queryMethod = "";
    if (evt.detail.name == "keyword") {
      queryMethod = "keyword";
    }
    if (evt.detail.name == "naics") {
      queryMethod = "naics";
    }
    if (queryMethod) {
      const url = new URL(window.location.href);
      url.searchParams.set(QUERY_METHOD_PARAM, queryMethod);
      window.history.pushState(null, null, url);
      this.queryMethodFieldTarget.value = queryMethod;
    }
  }
}
